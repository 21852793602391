import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";

function Nav() {
    const [isNavVisible, setIsNavVisible] = useState(false);
    const location = useLocation();
    const path = ["About", "Projects", "Contact"];

    const handleHamburgerClick = () => {
        setIsNavVisible(true);
    };
    const handleCloseClick = () => {
        setIsNavVisible(false);
    };

    const handleClick = () => {
        window.location.href = '/inquiry';
    };

    const snsLink = [
        {
            name: "instargram",
            link: "https://www.instagram.com/zipshowkorea/"
        },
        {
            name: "blog",
            link: "https://blog.naver.com/zipshowkorea"
        },
        {
            name: "youtube",
            link: "https://www.youtube.com/channel/UC3zJz6XURcSkkqMBMpEUiKA"
        },
    ];

    return (
        <>
            <div className="lg:flex hidden w-full h-20 justify-between items-center px-[60px] fixed bg-nav-gradient z-50">
                <div className="cursor-pointer">
                    <Link to="/" className="text-center">
                        <img src="/images/common/logo.svg" alt="logo" className="w-[140px]"/>
                    </Link>
                </div>
                <ul className="flex justify-between items-center gap-x-14 text-center text-white text-lg">
                    {
                        path.map((e, i) => {
                            const isActive = location.pathname === `/${e}` || (e === "Projects" && location.pathname.startsWith("/projects"));

                            return (
                                <li key={i} className={`cursor-pointer ${isActive ? 'text-[#E0474B] font-bold' : ''}`}>
                                    <Link to={`/${e}`}>
                                        <span className={`${isActive ? 'bg-[#E0474B] w-[6px] h-[6px] rounded-full inline-block mb-1' : ''}`}></span>
                                        &#160; {e}
                                    </Link>
                                </li>
                            )
                        })
                    }
                    <li className="cursor-pointer border border-white box-border text-sm rounded-full" key={location.key}>
                        <Link to="/inquiry" className="px-5 py-[10px] block" onClick={handleClick}>프로젝트 문의</Link>
                    </li>
                    {/*<li className="flex gap-x-6 relative after:absolute after:w-[1px] after:h-[10px] after:bg-[#999] after:top-1/2 after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2">
                        <p className="font-bold text-white">KR</p>
                        <p className="text-[#999]">EN</p>
                    </li>*/}
                </ul>
            </div>
            <ul className="lg:hidden flex w-full h-[60px] justify-between items-center px-5 fixed bg-nav-gradient z-50">
                <li>
                    <Link to="/" className="lg:hidden block cursor-pointer">
                        <img src="/images/common/logo.svg" alt="logo" className="w-[113px]"/>
                    </Link>
                </li>
                <li className="flex items-center gap-x-3 z-50">
                    {/*<div
                        className="flex gap-x-6 relative after:absolute after:w-[1px] after:h-[10px] after:bg-[#999] after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2">
                        <p className="font-bold text-white">KR</p>
                        <p className="text-[#999]">EN</p>
                    </div>*/}
                    <div className="cursor-pointer" onClick={handleHamburgerClick}>
                        <img src="/images/common/menu.svg" alt="menu"/>
                    </div>
                </li>

            </ul>
            {isNavVisible && (
                <div className="w-full h-full fixed z-50 bg-black pt-[84px] pl-8">
                    <div className="absolute top-5 right-5 cursor-pointer" onClick={handleCloseClick}>
                        <img src="/images/common/close.svg" alt="close"/>
                    </div>
                    <div className="">
                        <ul className="text-white text-[1.75em] font-bold">
                        {
                                path.map((e, i) => {
                                    const isActive = location.pathname === `/${e}` || (e === "Projects" && location.pathname.startsWith("/projects"));

                                    return (
                                        <li key={i} className={`mb-8 cursor-pointer ${isActive ? 'text-[#E0474B]' : ''}`}>
                                            <Link to={`/${e}`}>
                                                {e} &#160;
                                                <span className={`${isActive ? 'bg-[#E0474B] w-2 h-2 rounded-full inline-block mb-1.5' : ''}`}></span>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div>
                        <ul className="text-[#ccc] font-bold pt-[93px]">
                            <li className="mb-1 text-[#666]">E-Mail</li>
                            <li className="mb-4">zipshowkorea@gmail.com</li>
                            <li className="mb-1 text-[#666]">Tel</li>
                            <li className="mb-4">+82 70-5180-0011</li>
                            <li className="mb-1 text-[#666]">Fax</li>
                            <li className="mb-4">050-4399-1226</li>
                        </ul>
                        <div className="mt-10 flex gap-3">
                            {
                                snsLink.map((e, i) => {
                                    return (
                                        <Link to={e.link} target="_blank">
                                            <img src={`/images/common/icon_${e.name}.svg`} alt={e.name}/>
                                        </Link>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

Nav.propTypes = {};

export default Nav;