import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./component/Main";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Inquiry from "./pages/Inquiry";
import ProjectDetail from "./pages/ProjectDetail";
import ScrollToTop from "./ScrollToTop";
import ProjectDetail1 from "./pages/projectDetail/ProjectDetail1";
import ProjectDetail2 from "./pages/projectDetail/ProjectDetail2";
import CursorEffect from "./CursorEffect";
function App() {
    return (
        <BrowserRouter>
            {/*<CursorEffect />*/}
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects_detail" element={<ProjectDetail />} />
                <Route path="/projects_detail1" element={<ProjectDetail1 />} />
                <Route path="/projects_detail2" element={<ProjectDetail2 />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/inquiry" element={<Inquiry />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
